import { useEffect, useState } from "react";

import dayjs from "dayjs";

import { getActiveIssues, getPastIssues } from "../services/issues";
import { ErrorType, ErrorHistory } from "../types";

const parseErrors = (originalErrors: ErrorType[]): ErrorHistory[] => {
  const errors: ErrorHistory[] = [];

  let date = dayjs().year(2022).startOf("year");
  do {
    const filters = originalErrors.filter(
      (item) => date.unix() <= item.timestamp && item.timestamp < date.add(1, "month").unix(),
    );
    errors.push({
      timestamp: date.toString(),
      errors: filters,
    });

    date = date.add(1, "month");
  } while (date.unix() < dayjs().endOf("month").unix());

  return errors.reverse();
};

const useGetErrors = () => {
  const [history, setHistory] = useState<ErrorHistory[]>([]);
  const [current, setCurrent] = useState<ErrorType[]>([]);

  useEffect(() => {
    (async () => {
      const activeResponse = await getActiveIssues();
      const pastResponse = await getPastIssues();

      setCurrent(activeResponse?.Errors || []);
      setHistory(parseErrors(pastResponse?.Errors || []));
    })();
  }, []);

  return { history, current };
};

export default useGetErrors;
