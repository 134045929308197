import React, { FC } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Status from "./pages/Status";

const Router: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Status />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
