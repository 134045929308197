import { useState } from "react";

import CurrentStatus from "../components/CurrentStatus";
import History from "../components/History";
import useGetErrors from "../hooks/useGetErrors";
import logo from "../logo.svg";

const Status = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const { current, history } = useGetErrors();

  return (
    <div className="w-full min-h-screen bg-banner bg-top bg-cover py-24 px-8">
      <div className="max-w-6xl mx-auto">
        <div className="mb-4 flex items-center justify-between">
          <img width={220} src={logo} alt="logo" />
          <></>
          {/* <button type="button" className="text-sm rounded-full text-white font-bold tracking-wider bg-blue px-4 py-2">
            Subscribe To Updates
          </button> */}
        </div>

        <div className="shadow-card bg-blackTransparent rounded-xl mt-10 p-8">
          <p className="text-2xl font-bold text-white mb-10">System Status</p>

          <ul className="flex flex-wrap text-sm font-medium text-center border-b border-blue mb-12">
            <li className="mr-2">
              <a
                href="#"
                className={`inline-block px-4 py-2 text-white rounded-t-lg  ${
                  activeTab === 0 ? "active bg-blue" : "bg-transparent"
                }`}
                onClick={() => setActiveTab(0)}
              >
                Current Status
              </a>
            </li>
            <li className="mr-2">
              <a
                href="#"
                className={`inline-block px-4 py-2 text-white rounded-t-lg ${
                  activeTab === 1 ? "active bg-blue" : "bg-transparent"
                }`}
                onClick={() => setActiveTab(1)}
              >
                Incident History
              </a>
            </li>
          </ul>

          {activeTab === 0 ? <CurrentStatus status={current}/> : <History history={history} />}
        </div>
      </div>
    </div>
  );
};

export default Status;
