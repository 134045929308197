import { useState, useEffect } from "react";

import dayjs from "dayjs";

import { ErrorType } from "../types";

type Props = {
  status: ErrorType[];
};
const CurrentStatus = ({ status }: Props) => {
  const [current, setCurrent] = useState<number>();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent(Date.now());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <p className="text-2xl text-white font-bold mb-8">{dayjs(current).format("dddd, MMMM D, YYYY h:mm:ss A")}</p>
      {status.length === 0 ? (
        <>
          <div className="w-full rounded-xl bg-success p-4 text-white text-xs md:text-base md:font-bold text-center tracking-wider">
            All Services Operational
          </div>
          <div className="mt-8 w-full md:min-h-[200px]">
            <div className="mb-8 w-full rounded-full bg-transparent border-2 border-success px-4 py-2 text-xs md:text-base md:font-bold text-center text-success">
              XPND Deposits Operational
            </div>
            <div className="w-full rounded-full bg-transparent border-2 border-success px-4 py-2 text-xs md:text-base md:font-bold text-center text-success">
              XPND Withdrawals Operational
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="w-full rounded-xl bg-error p-4 text-white text-xs md:text-base md:font-bold text-center tracking-wider">
            Some services are potentially down right now. We will resolve this as soon as possible.
          </div>
          <div className="mt-8 w-full md:min-h-[200px]">
            {status.map((item) => (
              <div
                key={item.timestamp}
                className="mb-8 w-full rounded-full bg-transparent border-2 border-error px-4 py-2"
              >
                <div className="text-xs md:text-base md:font-bold text-center text-error">{item.exception}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default CurrentStatus;
