import axios from "axios";

import { API_URL, TOKEN_URL } from "../config";

export const getAccessToken = async () => {
  const params = new URLSearchParams();
  params.append("grant_type", "client_credentials");
  params.append("scope", "https://tr-apis.com/api.status");
  const response = await axios.post(`${TOKEN_URL}`, params, {
    headers: {
      Authorization:
        "Basic MW44ZXJ0NTg5N3FjbDk1NWF1bGttamRmcjU6Nm9qMXJwcGo4Z21rYzEyNTJuOTlpZ21vZTlvbW1pZmd2czRyN25sazNtc2ppNWNmcjdm",
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  return response.data;
};

export const getActiveIssues = async () => {
  const accessToken = await getAccessToken();
  const response = await axios.get(`${API_URL}/status/activeIssues`, {
    headers: {
      ServerAuthorization: `${accessToken.token_type} ${accessToken.access_token}`,
    },
  });

  return response.data;
};

export const getPastIssues = async () => {
  const accessToken = await getAccessToken();
  const response = await axios.get(`${API_URL}/status/pastIssues`, {
    headers: {
      ServerAuthorization: `${accessToken.token_type} ${accessToken.access_token}`,
    },
  });

  return response.data;
};
